var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.customersPurchaseCheques.purchaseCheques')))]),(
          _vm.$parent.item.stat == 1 &&
          _vm.$site.salesPayments_allow &&
          (_vm.$user.admin ||
            _vm.$user.role.sales_payments_add)
        )?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/purchaseCheques/create?customer_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.customersPurchaseCheques.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.customersPurchaseCheques.FindACheck'),"withoutCard":true,"emptyTableText":_vm.$parent.$t('components.customersPurchaseCheques.thereAreNosalesCheques'),"filter":{customer_id: _vm.$route.params.id},"emptyTableSubText":_vm.$parent.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
        { column: 'code', title: _vm.$t('components.customersPurchaseCheques.batch'), type: 'mainLink', sort: true },
        {
          column: 'customer_id',
          title: _vm.$t('components.customersPurchaseCheques.customerName'),
          type: 'link',
          to: 'customer',
          sort: true,
          link: true,
        },
        {
          column: 'created_date',
          title: _vm.$t('components.customersPurchaseCheques.RegistrationDate'),
          type: 'text',
          sort: true,
        },
        {
          column: 'date',
          title: _vm.$parent.$t('components.customersPurchaseCheques.paymentDate'),
          type: 'text',
          sort: true,
        },
        {
          column: 'description',
          title: _vm.$parent.$t('components.customersPurchaseCheques.thatsAbout'),
          type: 'text',
          sort: true,
        },
        {
          column: 'cost',
          title: _vm.$parent.$t('components.customersPurchaseCheques.amount'),
          type: 'text',
          sort: true,
        },
        {
          column: 'payment_method_id',
          title: _vm.$t('components.customersPurchaseCheques.PaymentMethod'),
          type: 'link',
          to: 'paymentMethod',
          sort: true,
          link: false,
        },
        {
          column: 'stat',
          title: _vm.$t('components.customersPurchaseCheques.active'),
          type: 'status',
          sort: true,
          values: [
            { title: _vm.$t('components.customersPurchaseCheques.Waiting'), value: 0, color: 'info' },
            { title: _vm.$t('components.customersPurchaseCheques.driven'), value: 1, color: 'success' } ],
        },
        {
          column: 'options',
          title: _vm.$parent.$t('components.customersPurchaseCheques.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role:
                _vm.$user.admin ||
                _vm.$user.role.sales_cheques_edit,
            },
            {
              name: 'pay',
              role:
                _vm.$user.admin ||
                _vm.$user.role.sales_cheques_edit,
            },
            {
              name: 'delete',
              role:
                _vm.$user.admin ||
                _vm.$user.role.sales_cheques_delete,
            } ],
        } ],"deleteText":{
        attention: _vm.$parent.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem:
          _vm.$parent.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$parent.$t('allerts.close'),
        confirm: _vm.$parent.$t('allerts.confirm'),
        loading: _vm.$parent.$t('allerts.loading'),
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }